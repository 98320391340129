import React from 'react'
import { Link } from 'gatsby'

import Layout, { PreFooter } from '../components/Layout'
import SEO from '../components/seo'

import Fade from 'react-reveal/Fade'

import { FiArrowUpRight } from 'react-icons/fi'

import SHOW_LOGO_1 from '../img/tv_show_logos/americas_steals_and_deals.png'
import SHOW_LOGO_2 from '../img/tv_show_logos/family_steals_and_deals.png'
import SHOW_LOGO_4 from '../img/tv_show_logos/local_steals_and_deals.png'
import SHOW_LOGO_5 from '../img/tv_show_logos/ofertas_locales.png'
import SHOW_LOGO_6 from '../img/tv_show_logos/RTM_deals.png'
import SHOW_LOGO_7 from '../img/tv_show_logos/weather_deals.png'
import SHOW_LOGO_8 from '../img/tv_show_logos/wendy_show_deals.png'

const SHOWS = [
  {
    img: SHOW_LOGO_4,
    title: 'Local Steals & Deals',
    link: 'https://localstealsanddeals.com/pages/contact-us',
  },
  {
    img: SHOW_LOGO_1,
    title: 'America’s Steals & Deals',
    link: 'https://americasstealsanddeals.com/pages/need-help',
  },
  {
    img: SHOW_LOGO_6,
    title: 'Right This Minute Deals',
    link: 'https://rtmdeals.com/pages/need-help',
  },
  {
    img: SHOW_LOGO_8,
    title: 'Wendy Show Deals',
    link: 'https://www.wendyshowdeals.com/pages/need-help',
  },
  {
    img: SHOW_LOGO_7,
    title: 'Weather Deals',
    link: 'https://weatherdeals.com/pages/need-help',
  },
  {
    img: SHOW_LOGO_5,
    title: 'Ofertas Locales',
    link: 'https://www.gangasanddeals.com/pages/informaci-n-del-contacto',
  },
  {
    img: SHOW_LOGO_2,
    title: 'Family Steals & Deals',
    link: 'https://familydeals.com/pages/need-help',
  }
]

function Contact() {
  return (
    <Layout
      //   location={this.props.location}
      //   title={siteTitle}
      HeaderExtension={
        <div className="header_extension no_bg aligncenter">
          <div className="bg">
            <div className="container">
              <Fade bottom duration={1000} delay={125} distance={'25%'}>
                <h2>Get onboard a better way</h2>
              </Fade>

              <Fade bottom duration={1000} delay={250} distance={'25%'}>
                <p>Think of this page as a launchpad</p>
              </Fade>
            </div>
          </div>
        </div>
      }
    >
      <SEO
        title="Contact"
        keywords={[
          'Knocking',
          'Contact Knocking',
          'Knocking business',
          'Knocking careers',
          'Ecommerce solution',
        ]}
        description="Contact us about Knocking business inquiries and Knocking careers. Discover our ecommerce solution and join the revolution!"
      />

      <div className="page-content pages-contact container">
        <div className="columns">
          <div className="column is-4">
            <div className="card">
              <h6>For your company</h6>

              <h3>Let’s talk about hot, fresh revenue opportunities.</h3>

              <p>
                <span>hello@knocking.com</span> <FiArrowUpRight />
              </p>

              <a href="mailto:hello@knocking.com"></a>
            </div>
          </div>

          <div className="column is-4">
            <div className="card">
              <h6>For your brand</h6>

              <h3>Let’s talk about spotlighting your product.</h3>

              <p>
                <span>Pitch us</span> <FiArrowUpRight />
              </p>

              <a
                href="https://knocking.typeform.com/to/A3a1lPX6"
                target="_blank"
              ></a>
            </div>
          </div>

          <div className="column is-4">
            <div className="card">
              <h6>For your career</h6>

              <h3>Let’s talk about a job that will change your life.</h3>

              <p>
                <span>See open roles</span>
              </p>

              <Link to="/careers"></Link>
            </div>
          </div>
        </div>

        <div className="cp-questions">
          <h3>Have a customer question?</h3>
          <p>Our Customer Experience Representatives are on standby.</p>

          <div className="columns is-multiline">
            {SHOWS.map(show => (
              <div className="column is-half">
                <a href={show.link} className="card" target="_blank">
                  <img src={show.img} />

                  <span className="cp-title">{show.title}</span>

                  <span className="cp-icon">
                    <FiArrowUpRight />
                  </span>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <PreFooter
        title="Excited yet?"
        linkText="Read about our recent launches"
        linkTo="/press"
      />
    </Layout>
  )
}

export default Contact
